import quizzlyAi from '/static/images/quizzlyAi.webp?url';

const ReadyToInstall = ({ setModalIsOpen }) => {
  return (
    <div className="bg-gradient-light-op container-center">
      <div className="main-container container-center">
        <div className="d-flex justify-content-between align-items-center w-100 ready-responsive-box">
          <div>
            <p className="readyToInstall">Ready to install Quizzly.ai?</p>
            <p className="contact-us-text">Don&apos;t hesitate to contact us</p>
            <div className="gradient-button" role="button" tabIndex={0} onClick={() => setModalIsOpen(true)}>
              Contact Us
            </div>
          </div>
          <img src={quizzlyAi} alt="quizzly.ai" className="quizzly" width={950} />
        </div>
      </div>
    </div>
  );
};

export default ReadyToInstall;
